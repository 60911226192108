// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-footer-js": () => import("./../../../src/templates/footer.js" /* webpackChunkName: "component---src-templates-footer-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-leadyourwork-page-js": () => import("./../../../src/templates/leadyourwork-page.js" /* webpackChunkName: "component---src-templates-leadyourwork-page-js" */),
  "component---src-templates-podcast-page-js": () => import("./../../../src/templates/podcast-page.js" /* webpackChunkName: "component---src-templates-podcast-page-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../../../src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */)
}

